<!-- eslint-disable max-len -->
<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20.871px" height="20px" viewBox="0 0 20.871 20" enable-background="new 0 0 20.871 20" xml:space="preserve">
    <g class="star_line">
      <path fill="#F9C61A" d="M10.434,0.999c0.033,0,0.08,0.018,0.101,0.067l2.382,5.575l0.235,0.549l0.594,0.054l6.026,0.547
        c0.042,0.004,0.078,0.033,0.092,0.074c0.014,0.043,0.002,0.09-0.033,0.12l-4.549,3.993l-0.447,0.394l0.132,0.58l1.345,5.917
        c0.006,0.026,0.002,0.054-0.015,0.08c-0.028,0.047-0.075,0.053-0.094,0.053c-0.02,0-0.038-0.006-0.056-0.017l-5.193-3.105
        l-0.514-0.307L9.927,15.88L4.72,18.989c-0.012,0.008-0.026,0.013-0.053,0.013c-0.007,0-0.015-0.001-0.025-0.001
        c-0.057-0.014-0.092-0.071-0.078-0.136l1.342-5.916l0.132-0.58L5.59,11.977L1.035,7.982C1.015,7.964,1.002,7.94,1,7.911
        C0.997,7.88,1.005,7.854,1.024,7.83c0.019-0.023,0.044-0.036,0.073-0.039l6.025-0.547L7.718,7.19l0.234-0.549l2.382-5.575
        c0.011-0.026,0.032-0.047,0.058-0.058C10.406,1.002,10.419,0.999,10.434,0.999 M10.434-0.001c-0.146,0-0.293,0.029-0.435,0.089
        C9.737,0.201,9.527,0.411,9.415,0.673L7.033,6.249L1.007,6.795c-0.61,0.056-1.06,0.596-1.004,1.206
        c0.025,0.284,0.159,0.547,0.374,0.734l4.554,3.993l-1.342,5.916c-0.139,0.596,0.23,1.191,0.826,1.33
        c0.083,0.02,0.168,0.029,0.253,0.029c0.2,0,0.397-0.055,0.571-0.16l5.2-3.105l5.193,3.105c0.179,0.106,0.375,0.158,0.569,0.158
        c0.377,0,0.744-0.192,0.952-0.539c0.148-0.246,0.195-0.539,0.131-0.818l-1.344-5.914l4.549-3.994
        c0.334-0.292,0.461-0.756,0.324-1.179s-0.512-0.724-0.955-0.763l-6.023-0.547l-2.382-5.575C11.275,0.252,10.865-0.001,10.434-0.001
        L10.434-0.001z"/>
    </g>
    <linearGradient id="aw" gradientUnits="userSpaceOnUse" x1="-449.2192" y1="275.1748" x2="-448.7197" y2="275.8811" gradientTransform="matrix(20.8709 0 0 -20.0043 9379.752 5524.1587)">
      <stop offset="0" style="stop-color:#F9C61A"/>
      <stop offset="1" style="stop-color:#FFE765"/>
    </linearGradient>
    <path class="star_body" fill="url(#aw)" d="M20.815,7.558c-0.136-0.423-0.513-0.724-0.955-0.763l-6.022-0.547l-2.383-5.575
      C11.214,0.11,10.563-0.152,10,0.088C9.737,0.201,9.527,0.41,9.415,0.673L7.033,6.248L1.008,6.795
      c-0.61,0.056-1.06,0.596-1.004,1.206c0.026,0.284,0.16,0.547,0.375,0.735l4.553,3.993L3.59,18.645
      c-0.139,0.595,0.23,1.19,0.826,1.33c0.281,0.065,0.577,0.019,0.825-0.131l5.2-3.105l5.194,3.105
      c0.525,0.314,1.206,0.145,1.521-0.381c0.146-0.246,0.193-0.539,0.129-0.818l-1.343-5.914l4.548-3.994
      C20.825,8.444,20.953,7.98,20.815,7.558z"/>
  </svg>
</template>

<script>
export default {
  name: 'OrderQuestionnaireStar',
  data() {
    return {
    };
  },
};
</script>
