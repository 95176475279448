<!-- eslint-disable max-len -->
<template>
  <div class="questionnaire_item">
    <h6>{{set.name}}</h6>
    <div class="ratebox">
      <Star
        v-for="n in 5"
        :key="n"
        :class="{ active: set.value >= n }"
        @click.native="changeRate(n)"/>
    </div>
    <input
      type="text"
      maxlength='50'
      placeholder="請輸入原因 (必填)"
      class="low_reason"
      v-model="set.reason"
      :readonly="set.readonly"
      v-if="(set.value <= 2 && set.value > 0)">
  </div>
</template>

<script>
import Star from '@/components/orderQuestionnaire/OrderQuestionnaireStar.vue';

export default {
  name: 'OrderQuestionnaireRateItem',
  props: ['set'],
  components: {
    Star,
  },
  data() {
    return {
    };
  },
  methods: {
    changeRate(n) {
      if (!this.set.readonly) {
        this.set.value = n;
      }
    },
  },
};
</script>
